<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="row">
        <p class="text-2xl font-bold">{{ form.title }}</p>
      </div>
      <hr>

      <!-- Document -->
      <div class="form-div mt-4">
        <p class="label col-span-2">ไฟล์เอกสาร</p>
        <div>
          <!-- View file -->
          <a :href="'https://carbon.it.kmitl.ac.th/pdf/old_document/' + form[1]" target="_blank">
            <button class="view-button mx-2" style="background-color: #FFC700;">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 16 16" stroke="white">
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
              </svg>
            </button>
          </a>

          <!-- Download file -->
          <button v-on:click="download()" class="download-button mx-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 24 24" stroke="white">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"

export default {
  name: "OldFormView",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      form: {
        title: null
      },
      project: null,
      role: null
    }
  },
  async created() {
    this.role = this.$store.getters.payload.role
    this.project = await this.$store.dispatch("getProjectById", this.$route.params.pid)
    this.form = this.project.old_project_details
    for (let form in this.form) {
      if (form == this.$route.params.form_name) {
        this.form = this.form[form]
      }
    }
    this.form.title = this.form[0]

    // Permission
    if (this.role == 'STD' && this.project.members.includes(this.$store.getters.payload.user_id) == false) {
      this.$router.push("/")
    }

    document.title = this.form[0] + " | Carbon"
    this.loading = false
  },
  methods: {
    async download() {
      await this.$store.dispatch("downloadDocumentFile", { project_id: this.$route.params.pid, name: this.form[1], type: "old_document" })
    },
  }
}
</script>